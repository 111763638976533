<script>
import p from './pdf.vue'
export default {
  extends: p,
  components: {},
  props: {

  },
  data () {
    return {
      title: this.$t('message.print', { name: this.$tc('message.appointments', 1) }),
      icon: 'print'
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setDoc (doc) {
      doc.autoPrint()
      window.open(doc.output('bloburl'), '_blank')
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
